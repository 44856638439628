<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment';

export default {
  locales: {
    en: {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    pt: {
      'Activation › Extract': 'Ativação › Extrato',
      'Date': 'Data',
      'Type': 'Tipo',
      'Description': 'Descrição',
      'Value': 'Valor',
      'Balance': 'Saldo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',

      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
    es: {
      'Activation › Extract': 'Activación › Extracto',
      'Date': 'Data',
      'Type': 'Tipo',
      'Description': 'Descripción',
      'Value': 'Valor',
      'Balance': 'Equilibrio',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',

      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    }
  },
  components: { Layout, VclList, Stat, VueMonthlyPicker },
  data() {
    return {
      calendar: "",
      monthly: {
        placeHolder: moment().format('MMM/YYYY'),
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      date: moment().format('YYYY-MM'),

      tableHeade: [
        'Date', 'Type', 'Description', 'Value', 'Balance'
      ],
      tableBody: null,

      loading: true,
      errored: false,
      empty: false,

      statData: null,
    };
  },
  methods: {
    getBalance() {
      api
        .get('wallet/balance/activation')
        .then(response => {
          if (response.data.status=='success') {
            this.statData = [
              {
                icon: "bx bx-dollar",
                title: "Current Balance",
                value: response.data.balance
              }
            ]
          }
        })
    },
    getList() {
      this.tableBody = null

      this.loading = true
      this.errored = false
      this.empty = false

      api
        .get('wallet/extract/activation?date='+this.date)
        .then(response => {
          if (response.data.status=='success') {
            this.tableBody = response.data.list
          } else {
            this.tableBody = null;
          }
        })
        .catch(error => {
          this.errored = error
        })
        .finally(() => {
          this.loading = false
          if (this.tableBody=='' || this.tableBody==null) {
            this.empty = true
          }
        })
    }
  },
  mounted() {
    this.getBalance()
    this.getList()
  },
  watch: {
    calendar: function (v) {
      this.date = moment(v._d).format('YYYY-MM')
      this.getList()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Activation › Extract') }}</h4>
          <div class="page-title-right">
            <vue-monthly-picker v-model="calendar" dateFormat="MMM/YYYY" :placeHolder="monthly.placeHolder" :monthLabels="monthly.labels" :clearOption="false" alignment="center"></vue-monthly-picker>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in tableHeade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in tableBody" :key="index">
                    <td v-for="(data,row) in td" :key="row" class="notranslate">{{ data }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>